import store from '@/store'
import { markRaw } from '@vue/reactivity'
import 'vue-router'

// Import icons
import IconHomeDefault from '@/components/icons/nav/IconHomeDefault.vue'
import IconHomeActive from '@/components/icons/nav/IconHomeActive.vue'
import IconConciergeDefault from '@/components/icons/nav/IconConciergeDefault.vue'
import IconConciergeActive from '@/components/icons/nav/IconConciergeActive.vue'
import IconExploreDefault from '@/components/icons/nav/IconExploreDefault.vue'
import IconExploreActive from '@/components/icons/nav/IconExploreActive.vue'
import IconMessagesDefault from '@/components/icons/nav/IconMessagesDefault.vue'
import IconMessagesActive from '@/components/icons/nav/IconMessagesActive.vue'
import IconMeDefault from '@/components/icons/nav/IconMeDefault.vue'
import IconMeActive from '@/components/icons/nav/IconMeActive.vue'
import { RouteLocationNormalized } from 'vue-router'



export const navRoutes = () => {
  return [
    {
      path: '/home',
      name: 'Home',
      component: () => import('@/pages/Home.vue'),
      meta: {
        title: 'Home',
        bottomNav: true,
        icon: {
          default: markRaw(IconHomeDefault),
          active: markRaw(IconHomeActive),
        },
      },
    },
    {
      path: '/concierge',
      name: 'Concierge',
      component: () => import('@/pages/Concierge.vue'),
      props: route => ({ fullPage: route.meta.fullPage }),
      meta: {
        title: 'Concierge',
        bottomNav: true,
        hasAccess: () => store.getters['auth/hasActiveBooking'],
        icon: {
          default: markRaw(IconConciergeDefault),
          active: markRaw(IconConciergeActive),
        },
      },
      children: [
        {
          path: 'appliance-guide/:id',
          name: 'Appliance Guide',
          component: () => import('@/pages/concierge/ApplianceGuide.vue'),
          props: route => ({ propertyAcronym: route.params.id }),
          meta: {
            title: 'Appliance Guide',
            header: { title: 'Appliance Guide', shouldHideBackIcon: () => !Boolean(store.getters['auth/loggedIn']), },
            bottomNav: false,
            fullPage: true,
            authRequired: false,
          },
        },
        {
          path: 'essential-info',
          name: 'EssentialInfo',
          component: () => import('@/pages/concierge/EssentialInfo.vue'),
          meta: {
            title: 'Essential Info',
            header: { title: 'Essential Info' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'cleaning',
          name: 'Cleaning',
          component: () => import('@/pages/concierge/Cleaning.vue'),
          meta: {
            title: 'Cleaning',
            header: { title: 'Cleaning request' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'amenities',
          name: 'Amenities',
          component: () => import('@/pages/concierge/Amenities.vue'),
          meta: {
            title: 'Amenities',
            header: { title: 'Amenities' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'checkin-checkout-time',
          name: 'CheckinCheckoutTime',
          component: () => import('@/pages/concierge/CheckinCheckoutTime.vue'),
          meta: {
            title: 'Check-in & Check-out Time',
            header: { title: 'Check-in & check-out time' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'modify-stay',
          name: 'ModifyStay',
          component: () => import('@/pages/concierge/ModifyStay.vue'),
          meta: {
            title: 'Modify Stay',
            header: { title: 'Modify Stay' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'city-gems',
          name: 'CityGems',
          component: () => import('@/pages/concierge/CityGems.vue'),
          meta: {
            title: 'City Gems',
            header: { title: 'City Gems' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'sauna-reservation-terms',
          name: 'SaunaReservationTerms',
          component: () => import('@/pages/concierge/SaunaReservationTerms.vue'),
          meta: {
            title: 'Sauna Reservation',
            header: { title: 'Sauna Reservation', classes: 'bg-white' },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true
          },
        },
        {
          path: 'sauna-reservation',
          name: 'SaunaReservation',
          component: () => import('@/pages/concierge/SaunaReservation.vue'),
          meta: {
            title: 'Sauna Reservation',
            header: { title: 'Sauna Reservation', classes: 'bg-white' },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true,
            async beforeResolve(_routeTo, _routeFrom, next) {

              const currentPropertyHasSauna = ['SLSW', 'TP'].includes(store.getters['auth/property']?.acronym)
              const currentBookingIsInStay = store.getters['auth/currentBooking'].status === 'in_stay'

              if (currentPropertyHasSauna && currentBookingIsInStay) await store.dispatch('sauna/fetchLatestReservation')

              const currentlyHasAReservation = store.getters['sauna/reservation'] !== null // TODO: check if the date and time is expired or not

              if (!currentPropertyHasSauna) {
                next({ name: 'Concierge' })
              } else if (!currentBookingIsInStay) {
                // Redirect to the coming soon page
                next({ name: 'SaunaReservationComingSoon' })

              } else if (currentlyHasAReservation) {
                next({ name: 'SaunaReservationDetails' })
              }
              // Otherwise, if the user has not agreed to the terms
              else if (!store.getters['auth/saunaTermsAgreed']) {
                // Redirect to the terms
                next({ name: 'SaunaReservationTerms' })
              } else {
                // Continue to the start page

                next()
              }
            },
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'sauna-reservation-error',
          name: 'SaunaReservationError',
          component: () => import('@/pages/concierge/SaunaReservationError.vue'),
          props: route => ({ errorMessage: route.query.errorMessage }),
          meta: {
            title: 'Sauna Reservation',
            header: { title: 'Sauna Reservation', classes: 'bg-white' },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'sauna-reservation-expired',
          name: 'SaunaReservationExpired',
          component: () => import('@/pages/concierge/SaunaReservationExpired.vue'),
          meta: {
            title: 'Sauna Reservation',
            header: { title: 'Sauna Reservation', classes: 'bg-white', backButtonGoesTo: { name: 'Concierge', replace: true } },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true,
            hasAccess: () => true,
          },
        },
        {
          path: 'sauna-reservation-details',
          name: 'SaunaReservationDetails',
          component: () => import('@/pages/concierge/SaunaReservationDetails.vue'),
          meta: {
            title: 'Sauna Reservation Details',
            header: {
              title: 'Sauna Reservation', classes: 'bg-white', backButtonGoesTo: { name: 'Concierge' }
            },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
            async beforeResolve(routeTo: RouteLocationNormalized, _routeFrom, next) {
              try {
                // The purpose of this is to redirect elsewhere if the Sauna Reservation that is being asked for is expired or invalid
                // (the links to this page come from confirmation emails, which could be clicked on at any point in time)

                const currentPropertyHasSauna = ['SLSW', 'TP'].includes(store.getters['auth/property']?.acronym)
                if (!currentPropertyHasSauna) throw new Error('expired')

                const currentBookingIsInStay = store.getters['auth/currentBooking'].status === 'in_stay'
                if (!currentBookingIsInStay) throw new Error('expired')

                await store.dispatch('sauna/fetchLatestReservation')

                const currentReservation = store.getters['sauna/reservation']
                if (!currentReservation?.reservationNumber) throw new Error('expired')

                if (
                  routeTo.query?.emailReservationNumber &&
                  (currentReservation.reservationNumber !== routeTo.query.emailReservationNumber)
                ) throw new Error('expired')

                next()
              } catch (e) {
                if (e.message = 'expired') {
                  next({ name: 'SaunaReservationExpired', replace: true })
                  return
                } else {
                  throw e
                }
              }
            },
          },
        },
        {
          path: 'sauna-reservation-coming-soon',
          name: 'SaunaReservationComingSoon',
          component: () => import('@/pages/concierge/SaunaReservationComingSoon.vue'),
          meta: {
            title: 'Sauna Reservation',
            header: { title: 'Sauna Reservation', classes: 'bg-white' },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true
          },
        },
        {
          path: 'sauna-cancellation-complete',
          name: 'SaunaCancellationComplete',
          component: () => import('@/pages/concierge/SaunaCancellationComplete.vue'),
          meta: {
            title: 'Sauna Reservation',
            header: { title: 'Sauna Reservation', classes: 'bg-white' },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true
          },
        },
      ],
    },
    // new explore
    {
      path: '/neighborhood', //lets change this to explore/:neighborhood-name once we do the routing ticket - changed now to prevent clashing routes
      name: 'Explore Neighborhood',
      component: () => import('@/pages/Explore2.vue'),
      props: route => ({ fullPage: route.meta.fullPage }),
      meta: {
        title: 'Explore',
        bottomNav: true,
        hasAccess: () => true,
        icon: {
          default: markRaw(IconExploreDefault),
          active: markRaw(IconExploreActive),
        },
      },
      children: [
        {
          path: 'city-gems',
          name: 'Explore City Gems',
          component: () => import('@/pages/explore/CityGems.vue'),
          meta: {
            title: 'City Gems',
            fullPage: true,
            hasAccess: () =>
              true
          },
        },
        {
          path: 'events',
          name: 'Explore Events',
          component: () => import('@/pages/explore/Events2.vue'),
          meta: {
            title: 'Events',
            fullPage: true,

            hasAccess: () =>
              store.getters['auth/hasActiveBooking'] ||
              store.getters['auth/hasUpcomingBookings'] ||
              store.getters['auth/isStaff'],
          },
        },
        {
          path: '/events/:id',
          name: 'Event',
          component: () => import('@/pages/explore/Event.vue'),
          props: route => ({ eventId: parseInt(route.params.id) }),
          meta: {
            header: { title: 'Event Details' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () =>
              store.getters['auth/hasActiveBooking'] ||
              store.getters['auth/hasUpcomingBookings'] ||
              store.getters['auth/isStaff'],
          },
        },
        {
          path: 'city_happenings',
          name: 'Explore City Happenings',
          component: () => import('@/pages/explore/CityHappenings2.vue'),
          meta: {
            fullPage: true,
            title: 'City Happenings',
            hasAccess: () => true
          },
        },
        {
          path: 'neighbors',
          name: 'Explore Neighbors',
          component: () => import('@/pages/explore/Neighbors2.vue'),
          meta: {
            title: 'Neighbors',
            fullPage: true,
            hasAccess: () =>
              store.getters['auth/hasActiveBooking'] ||
              store.getters['auth/isStaff'],
          },
        },
      ],
    },
    {
      path: '/messages',
      name: 'Messages',
      component: () => import('@/pages/Messages.vue'),
      meta: {
        title: 'Messages',
        bottomNav: true,
        hasAccess: () => true,
        icon: {
          default: markRaw(IconMessagesDefault),
          active: markRaw(IconMessagesActive),
        },
      },
      redirect: () => ({ name: 'Chats' }),
      children: [
        {
          path: 'chats',
          name: 'Chats',
          component: () => import('@/pages/messages/Chats.vue'),
          meta: {
            title: 'Chats',
            bottomNav: true,
            hasAccess: () =>
              !!store.getters['auth/currentBooking'] ||
              store.getters['auth/isStaff'],
          },
        },
        {
          path: 'chats/:id',
          name: 'Chat',
          component: () => import('@/pages/messages/Chat.vue'),
          props: route => ({ chatId: parseInt(route.params.id) }),
          meta: {
            header: { classes: 'bg-white' },
            bottomNav: false,
            hasAccess: () =>
              store.getters['auth/currentBooking'] ||
              store.getters['auth/isStaff'],
          },
          beforeEnter: async to => {
            // Load chats and set header
            await store.dispatch('chats/fetchChats')
            const chat = store.getters['chats/chats'].find(
              c => c.id === parseInt(to.params.id)
            )
            if (!chat) return

            to.meta.header.title = chat.name
            // if Property Group Chat
            if (chat.property && !chat.announcement) {
              // TODO: Pass the ChatAvatars component dynamically
              to.meta.header.slot = {
                component: 'ChatAvatars',
                props: { chatId: parseInt(to.params.id) },
              }
            } else {
              // reset header slot
              to.meta.header.slot = null
            }
            to.meta.header = {
              ...to.meta.header,
              // keep other headers set in route
              ...to.matched[to.matched.length - 1].meta.header,
            }
          },
        },
        {
          path: 'chats/:id/profiles',
          name: 'ChatProfiles',
          component: () => import('@/pages/messages/ChatProfiles.vue'),
          props: route => ({ chatId: parseInt(route.params.id) }),
          meta: {
            header: { title: 'Guests in group chat' },
            bottomNav: false,
            hasAccess: () =>
              store.getters['auth/currentBooking'] ||
              store.getters['auth/isStaff'],
          },
        },
      ],
    },
    {
      path: '/',
      name: 'Me',
      component: () => import('@/components/PassThrough.vue'),
      props: route => ({ fullPage: route.meta.fullPage }),
      meta: {
        title: 'Me',
        bottomNav: false,
        icon: {
          default: markRaw(IconMeDefault),
          active: markRaw(IconMeActive),
        },
      },
      redirect: () => ({ path: '/me' }),
      children: [
        {
          path: 'me',
          name: 'Me',
          component: () => import('@/pages/Me.vue'),
          meta: {
            title: 'Me',
            bottomNav: true,
          },
        },
        {
          path: 'profile',
          name: 'Profile',
          component: () => import('@/pages/me/Profile.vue'),
          meta: {
            header: { title: 'My profile' },
          },
        },
        {
          path: 'account',
          name: 'Account',
          component: () => import('@/pages/me/Account.vue'),
          meta: {
            header: { title: 'Account' },
          },
        },
        {
          path: 'info',
          name: 'Info',
          component: () => import('@/pages/me/Info.vue'),
          meta: {
            header: { title: 'Personal info' },
          },
        },
        {
          path: 'bookings/:bookingNo',
          name: 'Booking',
          component: () => import('@/pages/me/BookingDetails.vue'),
          props: route => ({ bookingNo: parseInt(route.params.bookingNo) }),
          meta: {
            header: { title: 'Booking details' },
          },
        },
      ],
    },
  ]
}

// remove after new city gems release
export const oldNavRoutes = () => {
  return [
    {
      path: '/home',
      name: 'Home',
      component: () => import('@/pages/Home.vue'),
      meta: {
        title: 'Home',
        bottomNav: true,
        icon: {
          default: markRaw(IconHomeDefault),
          active: markRaw(IconHomeActive),
        },
      },
    },
    {
      path: '/concierge',
      name: 'Concierge',
      component: () => import('@/pages/Concierge.vue'),
      props: route => ({ fullPage: route.meta.fullPage }),
      meta: {
        title: 'Concierge',
        bottomNav: true,
        hasAccess: () => store.getters['auth/hasActiveBooking'],
        icon: {
          default: markRaw(IconConciergeDefault),
          active: markRaw(IconConciergeActive),
        },
      },
      children: [
        {
          path: 'appliance-guide/:id',
          name: 'Appliance Guide',
          component: () => import('@/pages/concierge/ApplianceGuide.vue'),
          props: route => ({ propertyAcronym: route.params.id }),
          meta: {
            title: 'Appliance Guide',
            header: { title: 'Appliance Guide', shouldHideBackIcon: () => !Boolean(store.getters['auth/loggedIn']), },
            bottomNav: false,
            fullPage: true,
            authRequired: false,
          },
        },
        {
          path: 'essential-info',
          name: 'EssentialInfo',
          component: () => import('@/pages/concierge/EssentialInfo.vue'),
          meta: {
            title: 'Essential Info',
            header: { title: 'Essential Info' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'cleaning',
          name: 'Cleaning',
          component: () => import('@/pages/concierge/Cleaning.vue'),
          meta: {
            title: 'Cleaning',
            header: { title: 'Cleaning request' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'amenities',
          name: 'Amenities',
          component: () => import('@/pages/concierge/Amenities.vue'),
          meta: {
            title: 'Amenities',
            header: { title: 'Amenities' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'checkin-checkout-time',
          name: 'CheckinCheckoutTime',
          component: () => import('@/pages/concierge/CheckinCheckoutTime.vue'),
          meta: {
            title: 'Check-in & Check-out Time',
            header: { title: 'Check-in & check-out time' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'modify-stay',
          name: 'ModifyStay',
          component: () => import('@/pages/concierge/ModifyStay.vue'),
          meta: {
            title: 'Modify Stay',
            header: { title: 'Modify Stay' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'city-gems',
          name: 'CityGems',
          component: () => import('@/pages/concierge/CityGems.vue'),
          meta: {
            title: 'City Gems',
            header: { title: 'City Gems' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'sauna-reservation-terms',
          name: 'SaunaReservationTerms',
          component: () => import('@/pages/concierge/SaunaReservationTerms.vue'),
          meta: {
            title: 'Sauna Reservation',
            header: { title: 'Sauna Reservation', classes: 'bg-white' },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true
          },
        },
        {
          path: 'sauna-reservation',
          name: 'SaunaReservation',
          component: () => import('@/pages/concierge/SaunaReservation.vue'),
          meta: {
            title: 'Sauna Reservation',
            header: { title: 'Sauna Reservation', classes: 'bg-white' },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true,
            async beforeResolve(_routeTo, _routeFrom, next) {

              const currentPropertyHasSauna = ['SLSW', 'TP'].includes(store.getters['auth/property']?.acronym)
              const currentBookingIsInStay = store.getters['auth/currentBooking'].status === 'in_stay'

              if (currentPropertyHasSauna && currentBookingIsInStay) await store.dispatch('sauna/fetchLatestReservation')

              const currentlyHasAReservation = store.getters['sauna/reservation'] !== null // TODO: check if the date and time is expired or not

              if (!currentPropertyHasSauna) {
                next({ name: 'Concierge' })
              } else if (!currentBookingIsInStay) {
                // Redirect to the coming soon page
                next({ name: 'SaunaReservationComingSoon' })

              } else if (currentlyHasAReservation) {
                next({ name: 'SaunaReservationDetails' })
              }
              // Otherwise, if the user has not agreed to the terms
              else if (!store.getters['auth/saunaTermsAgreed']) {
                // Redirect to the terms
                next({ name: 'SaunaReservationTerms' })
              } else {
                // Continue to the start page

                next()
              }
            },
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'sauna-reservation-error',
          name: 'SaunaReservationError',
          component: () => import('@/pages/concierge/SaunaReservationError.vue'),
          props: route => ({ errorMessage: route.query.errorMessage }),
          meta: {
            title: 'Sauna Reservation',
            header: { title: 'Sauna Reservation', classes: 'bg-white' },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
          },
        },
        {
          path: 'sauna-reservation-expired',
          name: 'SaunaReservationExpired',
          component: () => import('@/pages/concierge/SaunaReservationExpired.vue'),
          meta: {
            title: 'Sauna Reservation',
            header: { title: 'Sauna Reservation', classes: 'bg-white', backButtonGoesTo: { name: 'Concierge', replace: true } },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true,
            hasAccess: () => true,
          },
        },
        {
          path: 'sauna-reservation-details',
          name: 'SaunaReservationDetails',
          component: () => import('@/pages/concierge/SaunaReservationDetails.vue'),
          meta: {
            title: 'Sauna Reservation Details',
            header: {
              title: 'Sauna Reservation', classes: 'bg-white', backButtonGoesTo: { name: 'Concierge' }
            },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true,
            hasAccess: () => store.getters['auth/hasActiveBooking'],
            async beforeResolve(routeTo: RouteLocationNormalized, _routeFrom, next) {
              try {
                // The purpose of this is to redirect elsewhere if the Sauna Reservation that is being asked for is expired or invalid
                // (the links to this page come from confirmation emails, which could be clicked on at any point in time)

                const currentPropertyHasSauna = ['SLSW', 'TP'].includes(store.getters['auth/property']?.acronym)
                if (!currentPropertyHasSauna) throw new Error('expired')

                const currentBookingIsInStay = store.getters['auth/currentBooking'].status === 'in_stay'
                if (!currentBookingIsInStay) throw new Error('expired')

                await store.dispatch('sauna/fetchLatestReservation')

                const currentReservation = store.getters['sauna/reservation']
                if (!currentReservation?.reservationNumber) throw new Error('expired')

                if (
                  routeTo.query?.emailReservationNumber &&
                  (currentReservation.reservationNumber !== routeTo.query.emailReservationNumber)
                ) throw new Error('expired')

                next()
              } catch (e) {
                if (e.message = 'expired') {
                  next({ name: 'SaunaReservationExpired', replace: true })
                  return
                } else {
                  throw e
                }
              }
            },
          },
        },
        {
          path: 'sauna-reservation-coming-soon',
          name: 'SaunaReservationComingSoon',
          component: () => import('@/pages/concierge/SaunaReservationComingSoon.vue'),
          meta: {
            title: 'Sauna Reservation',
            header: { title: 'Sauna Reservation', classes: 'bg-white' },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true
          },
        },
        {
          path: 'sauna-cancellation-complete',
          name: 'SaunaCancellationComplete',
          component: () => import('@/pages/concierge/SaunaCancellationComplete.vue'),
          meta: {
            title: 'Sauna Reservation',
            header: { title: 'Sauna Reservation', classes: 'bg-white' },
            bottomNav: false,
            fullPage: true,
            whiteBackground: true
          },
        },
      ],
    },
    {
      path: '/explore',
      name: 'Explore',
      component: () => import('@/pages/Explore.vue'),
      props: route => ({ fullPage: route.meta.fullPage }),
      meta: {
        title: 'Explore',
        bottomNav: true,
        hasAccess: () => true,
        icon: {
          default: markRaw(IconExploreDefault),
          active: markRaw(IconExploreActive),
        },
      },
      redirect: () => {
        return store.getters['auth/hasActiveBooking'] || store.getters['auth/hasUpcomingBookings'] ||
          store.getters['auth/isStaff'] ? { name: 'Events' } : { name: 'City Happenings' }
      },
      children: [
        {
          path: 'events',
          name: 'Events',
          component: () => import('@/pages/explore/Events.vue'),
          meta: {
            title: 'Events',
            hasAccess: () =>
              store.getters['auth/hasActiveBooking'] ||
              store.getters['auth/hasUpcomingBookings'] ||
              store.getters['auth/isStaff'],
          },
        },
        {
          path: '/events/:id',
          name: 'Event',
          component: () => import('@/pages/explore/Event.vue'),
          props: route => ({ eventId: parseInt(route.params.id) }),
          meta: {
            header: { title: 'Event Details' },
            bottomNav: false,
            fullPage: true,
            hasAccess: () =>
              store.getters['auth/hasActiveBooking'] ||
              store.getters['auth/hasUpcomingBookings'] ||
              store.getters['auth/isStaff'],
          },
        },
        {
          path: 'city_happenings',
          name: 'City Happenings',
          component: () => import('@/pages/explore/CityHappenings.vue'),
          meta: {
            title: 'City Happenings',
            hasAccess: () => true
          },
        },
        {
          path: 'neighbors',
          name: 'Neighbors',
          component: () => import('@/pages/explore/Neighbors.vue'),
          meta: {
            title: 'Neighbors',
            hasAccess: () =>
              store.getters['auth/hasActiveBooking'] ||
              store.getters['auth/isStaff'],
          },
        },
      ],
    },
    {
      path: '/messages',
      name: 'Messages',
      component: () => import('@/pages/Messages.vue'),
      meta: {
        title: 'Messages',
        bottomNav: true,
        hasAccess: () => true,
        icon: {
          default: markRaw(IconMessagesDefault),
          active: markRaw(IconMessagesActive),
        },
      },
      redirect: () => ({ name: 'Chats' }),
      children: [
        {
          path: 'chats',
          name: 'Chats',
          component: () => import('@/pages/messages/Chats.vue'),
          meta: {
            title: 'Chats',
            bottomNav: true,
            hasAccess: () =>
              !!store.getters['auth/currentBooking'] ||
              store.getters['auth/isStaff'],
          },
        },
        {
          path: 'chats/:id',
          name: 'Chat',
          component: () => import('@/pages/messages/Chat.vue'),
          props: route => ({ chatId: parseInt(route.params.id) }),
          meta: {
            header: { classes: 'bg-white' },
            bottomNav: false,
            hasAccess: () =>
              store.getters['auth/currentBooking'] ||
              store.getters['auth/isStaff'],
          },
          beforeEnter: async to => {
            // Load chats and set header
            await store.dispatch('chats/fetchChats')
            const chat = store.getters['chats/chats'].find(
              c => c.id === parseInt(to.params.id)
            )
            if (!chat) return

            to.meta.header.title = chat.name
            // if Property Group Chat
            if (chat.property && !chat.announcement) {
              // TODO: Pass the ChatAvatars component dynamically
              to.meta.header.slot = {
                component: 'ChatAvatars',
                props: { chatId: parseInt(to.params.id) },
              }
            } else {
              // reset header slot
              to.meta.header.slot = null
            }
            to.meta.header = {
              ...to.meta.header,
              // keep other headers set in route
              ...to.matched[to.matched.length - 1].meta.header,
            }
          },
        },
        {
          path: 'chats/:id/profiles',
          name: 'ChatProfiles',
          component: () => import('@/pages/messages/ChatProfiles.vue'),
          props: route => ({ chatId: parseInt(route.params.id) }),
          meta: {
            header: { title: 'Guests in group chat' },
            bottomNav: false,
            hasAccess: () =>
              store.getters['auth/currentBooking'] ||
              store.getters['auth/isStaff'],
          },
        },
      ],
    },
    {
      path: '/',
      name: 'Me',
      component: () => import('@/components/PassThrough.vue'),
      props: route => ({ fullPage: route.meta.fullPage }),
      meta: {
        title: 'Me',
        bottomNav: false,
        icon: {
          default: markRaw(IconMeDefault),
          active: markRaw(IconMeActive),
        },
      },
      redirect: () => ({ path: '/me' }),
      children: [
        {
          path: 'me',
          name: 'Me',
          component: () => import('@/pages/Me.vue'),
          meta: {
            title: 'Me',
            bottomNav: true,
          },
        },
        {
          path: 'profile',
          name: 'Profile',
          component: () => import('@/pages/me/Profile.vue'),
          meta: {
            header: { title: 'My profile' },
          },
        },
        {
          path: 'account',
          name: 'Account',
          component: () => import('@/pages/me/Account.vue'),
          meta: {
            header: { title: 'Account' },
          },
        },
        {
          path: 'info',
          name: 'Info',
          component: () => import('@/pages/me/Info.vue'),
          meta: {
            header: { title: 'Personal info' },
          },
        },
        {
          path: 'bookings/:bookingNo',
          name: 'Booking',
          component: () => import('@/pages/me/BookingDetails.vue'),
          props: route => ({ bookingNo: parseInt(route.params.bookingNo) }),
          meta: {
            header: { title: 'Booking details' },
          },
        },
      ],
    },
  ]
}

const redirectRoutes = () => [
  {
    path: '/redirect-to-checkin',
    name: 'GoToCheckin',
    redirect: () => {
      window.location.href = 'https://checkin.section-l.co/checkin/code'
      return '/redirecting' // not important since redirecting
    },
  },
  {
    path: "/redirect-to-booking-engine",
    name: 'GoToBookingEngine',
    redirect: () => {
      window.location.href = "https://section-l.co/book";
      return '/redirecting' // not important since redirecting
    }
  },
  ...(Object.entries({
    '/property-info/slae/house-appliance-guide/en': 'https://res.cloudinary.com/sectionl/image/upload/v1672804420/propertyPDFs/Asakusa%20East/AE_Appliance_Manual_English_vrlgto.pdf',
    '/property-info/slae/house-appliance-guide/ja': 'https://res.cloudinary.com/sectionl/image/upload/v1670390253/propertyPDFs/Asakusa%20East/AE_Appliance_Manual_Japanese_x52sic.pdf',
    '/property-info/slge/house-appliance-guide/en': 'https://res.cloudinary.com/sectionl/image/upload/v1676972344/propertyPDFs/Ginza%20East/GE_Appliance_Guide_English_rcvs6w.pdf',
    '/property-info/slge/house-appliance-guide/ja': 'https://res.cloudinary.com/sectionl/image/upload/v1670390214/propertyPDFs/Ginza%20East/GE_Appliance_Manual_Japanese_jmjaz4.pdf',
    '/property-info/slhb/house-appliance-guide/en': 'https://res.cloudinary.com/sectionl/image/upload/v1672804361/propertyPDFs/Hatchobori/HB_Appliance_Manual_English_richbk.pdf',
    '/property-info/slhb/house-appliance-guide/ja': 'https://res.cloudinary.com/sectionl/image/upload/v1672803663/propertyPDFs/Hatchobori/HB_Appliance_Manual_Japanese_gjgnz1.pdf',
    '/property-info/slae/aircon': 'https://res.cloudinary.com/sectionl/image/upload/v1654578405/propertyPDFs/Asakusa%20East/AE-AirCon_spfylo.pdf',
    '/property-info/slae/bathroomfan': 'https://res.cloudinary.com/sectionl/image/upload/v1654578676/propertyPDFs/Asakusa%20East/AE-Bathroom_Fan_ach586.pdf',
    '/property-info/slae/hotwater': 'https://res.cloudinary.com/sectionl/image/upload/v1654578676/propertyPDFs/Asakusa%20East/AE-Hot_Water_mtalsl.pdf',
    '/property-info/slae/intercom': 'https://res.cloudinary.com/sectionl/image/upload/v1654578677/propertyPDFs/Asakusa%20East/AE-Intercom_ix4c0j.pdf',
    '/property-info/slae/ricecooker': 'https://res.cloudinary.com/sectionl/image/upload/v1654578678/propertyPDFs/Asakusa%20East/AE-Rice_Cooker_n7khsf.pdf',
    '/property-info/slae/tvremote': 'https://res.cloudinary.com/sectionl/image/upload/v1654578678/propertyPDFs/Asakusa%20East/AE-TV_Remote_mxjxz1.pdf',
    '/property-info/slae/washingmachine': 'https://res.cloudinary.com/sectionl/image/upload/v1654578678/propertyPDFs/Asakusa%20East/AE-Washing_Machine_fohe6s.pdf',
    '/property-info/slae/washlet': 'https://res.cloudinary.com/sectionl/image/upload/v1654578677/propertyPDFs/Asakusa%20East/AE-Washlet_gwxfst.pdf',
    '/property-info/slge/aircon': 'https://res.cloudinary.com/sectionl/image/upload/v1654577819/propertyPDFs/Ginza%20East/GE-AirCon_qjonaj.pdf',
    '/property-info/slge/bathroomfan': 'https://res.cloudinary.com/sectionl/image/upload/v1654577821/propertyPDFs/Ginza%20East/GE-Bathroom_Fan_yhtbta.pdf',
    '/property-info/slge/dishwasher': 'https://res.cloudinary.com/sectionl/image/upload/v1654577820/propertyPDFs/Ginza%20East/GE-Dishwasher_yba7wb.pdf',
    '/property-info/slge/grill': 'https://res.cloudinary.com/sectionl/image/upload/v1654577964/propertyPDFs/Ginza%20East/GE-Grill_lraenl.pdf',
    '/property-info/slge/hotwater1': 'https://res.cloudinary.com/sectionl/image/upload/v1654577822/propertyPDFs/Ginza%20East/GE-Hot_Water_pbusrf.pdf',
    '/property-info/slge/hotwater2': 'https://res.cloudinary.com/sectionl/image/upload/v1654673112/propertyPDFs/Ginza%20East/GE-Hot_Water_2_swsmek.pdf',
    '/property-info/slge/ricecooker': 'https://res.cloudinary.com/sectionl/image/upload/v1654577964/propertyPDFs/Ginza%20East/GE-Rice_Cooker_xxe7t5.pdf',
    '/property-info/slge/tvremote': 'https://res.cloudinary.com/sectionl/image/upload/v1654577820/propertyPDFs/Ginza%20East/GE-TV_Remote_qedyqb.pdf',
    '/property-info/slge/floorheating': 'https://res.cloudinary.com/sectionl/image/upload/v1654577821/propertyPDFs/Ginza%20East/GE-Floor_Heating_upqck4.pdf',
    '/property-info/slge/washingmachine': 'https://res.cloudinary.com/sectionl/image/upload/v1654577821/propertyPDFs/Ginza%20East/GE-Washing_Machine_onr3ow.pdf',
    '/property-info/slge/washlet1': 'https://res.cloudinary.com/sectionl/image/upload/v1654577821/propertyPDFs/Ginza%20East/GE-Washlet_ylxzet.pdf',
    '/property-info/slge/washlet2': 'https://res.cloudinary.com/sectionl/image/upload/v1654577964/propertyPDFs/Ginza%20East/GE-Washlet_2_hsozta.pdf',
    '/property-info/slhb/aircon': 'https://res.cloudinary.com/sectionl/image/upload/v1672451998/propertyPDFs/Hatchobori/HB-AirCon_o5km57.pdf',
    '/property-info/slhb/hotwater': 'https://res.cloudinary.com/sectionl/image/upload/v1670557197/propertyPDFs/Hatchobori/HB-Hot_Water_ycijfc.pdf',
    '/property-info/slhb/ricecooker': 'https://res.cloudinary.com/sectionl/image/upload/v1670556980/propertyPDFs/Hatchobori/HB-Rice_Cooker_bqjnw3.pdf',
    '/property-info/slhb/tvremote': 'https://res.cloudinary.com/sectionl/image/upload/v1670557184/propertyPDFs/Hatchobori/HB-TV_Remote_h4b0y1.pdf',
    '/property-info/slhb/washingmachine': 'https://res.cloudinary.com/sectionl/image/upload/v1670556980/propertyPDFs/Hatchobori/HB-Washing_Machine_tly88t.pdf',
    '/property-info/slhb/washlet': 'https://res.cloudinary.com/sectionl/image/upload/v1670557192/propertyPDFs/Hatchobori/HB-Washlet_uwstwx.pdf',
    '/property-info/slhb/microwave': 'https://res.cloudinary.com/sectionl/image/upload/v1670556980/propertyPDFs/Hatchobori/HB-Microwave_fa5ske.pdf',
    '/property-info/slhb/toaster': 'https://res.cloudinary.com/sectionl/image/upload/v1670556980/propertyPDFs/Hatchobori/HB-Toaster_o9lrd2.pdf',
    '/property-info/slrg/house-appliance-guide/en': 'https://res.cloudinary.com/sectionl/image/upload/v1677500606/propertyPDFs/Residence%20Ginza/RG_Appliance_Guide_English_wnlspe.pdf',
    '/property-info/slrg/house-appliance-guide/ja': 'https://res.cloudinary.com/sectionl/image/upload/v1677500586/propertyPDFs/Residence%20Ginza/RG_Appliance_Guide_Japanese_tktexz.pdf',
    '/property-info/slrg/intercom': 'https://res.cloudinary.com/sectionl/image/upload/v1677500585/propertyPDFs/Residence%20Ginza/RG_Intercom_ns6joa.pdf',
    '/property-info/slrg/aircon1': 'https://res.cloudinary.com/sectionl/image/upload/v1677500583/propertyPDFs/Residence%20Ginza/RG_Aircon_1_h81z11.pdf',
    '/property-info/slrg/aircon2': 'https://res.cloudinary.com/sectionl/image/upload/v1677500583/propertyPDFs/Residence%20Ginza/RG_Aircon_2_ebkot9.pdf',
    '/property-info/slrg/aircon3': 'https://res.cloudinary.com/sectionl/image/upload/v1677500585/propertyPDFs/Residence%20Ginza/RG_Aircon_3_ervf2x.pdf',
    '/property-info/slrg/aircon4': 'https://res.cloudinary.com/sectionl/image/upload/v1677500583/propertyPDFs/Residence%20Ginza/RG_Aircon_4_u7o0v7.pdf',
    '/property-info/slrg/aircon5': 'https://res.cloudinary.com/sectionl/image/upload/v1705655712/propertyPDFs/Residence%20Ginza/RG_Aircon_5_f3k4eh.pdf',
    '/property-info/slrg/hotwater': 'https://res.cloudinary.com/sectionl/image/upload/v1677500587/propertyPDFs/Residence%20Ginza/RG_HotWater_nlf2h9.pdf',
    '/property-info/slrg/ricecooker': 'https://res.cloudinary.com/sectionl/image/upload/v1677500586/propertyPDFs/Residence%20Ginza/RG_RiceCooker_p6thog.pdf',
    '/property-info/slrg/microwave': 'https://res.cloudinary.com/sectionl/image/upload/v1677500585/propertyPDFs/Residence%20Ginza/RG_Microwave_pzzx52.pdf',
    '/property-info/slrg/tvremote': 'https://res.cloudinary.com/sectionl/image/upload/v1677500587/propertyPDFs/Residence%20Ginza/RG_TVRemote_xhxri2.pdf',
    '/property-info/slrg/washingmachine': 'https://res.cloudinary.com/sectionl/image/upload/v1677500589/propertyPDFs/Residence%20Ginza/RG_WashingMachine_m2cnpw.pdf',
    '/property-info/slrg/washlet': 'https://res.cloudinary.com/sectionl/image/upload/v1677500586/propertyPDFs/Residence%20Ginza/RG_Washlet_c48fcy.pdf',
    '/property-info/slrg/washlet2': 'https://res.cloudinary.com/sectionl/image/upload/v1705655772/propertyPDFs/Residence%20Ginza/RG-Washlet_2_bte3mn.pdf',
    '/property-info/slhm/house-appliance-guide/en': 'https://res.cloudinary.com/sectionl/image/upload/v1678848002/propertyPDFs/Hamamatsucho/HM_Appliance_Guide_English_gq5scc.pdf',
    '/property-info/slhm/aircon1': 'https://res.cloudinary.com/sectionl/image/upload/v1676972486/propertyPDFs/Hamamatsucho/HM-AirCon_1_bqtawu.pdf',
    '/property-info/slhm/aircon2': 'https://res.cloudinary.com/sectionl/image/upload/v1676972488/propertyPDFs/Hamamatsucho/HM-AirCon_2_vad8or.pdf',
    '/property-info/slhm/aircon3': 'https://res.cloudinary.com/sectionl/image/upload/v1679043475/propertyPDFs/Hamamatsucho/HM-Aircon_3_t4uojb.pdf',
    '/property-info/slhm/tvremote': 'https://res.cloudinary.com/sectionl/image/upload/v1676972487/propertyPDFs/Hamamatsucho/HM-TV_gsvonf.pdf',
    '/property-info/slhm/washingmachine': 'https://res.cloudinary.com/sectionl/image/upload/v1676972487/propertyPDFs/Hamamatsucho/HM-Washing_Machine_aopsup.pdf',
    '/property-info/slhm/light': 'https://res.cloudinary.com/sectionl/image/upload/v1676972486/propertyPDFs/Hamamatsucho/HM-Light_yap4e4.pdf',
    '/property-info/slhm/microwave': 'https://res.cloudinary.com/sectionl/image/upload/v1676972487/propertyPDFs/Hamamatsucho/HM-Microwave_rrp2wi.pdf',
    '/property-info/slhm/toaster': 'https://res.cloudinary.com/sectionl/image/upload/v1676972487/propertyPDFs/Hamamatsucho/HM-Toaster_eeynfs.pdf',
    '/property-info/slhm/washlet': 'https://res.cloudinary.com/sectionl/image/upload/v1676972487/propertyPDFs/Hamamatsucho/HM-Washlet_tfeqtn.pdf',
    '/property-info/slhm/hotwater': 'https://res.cloudinary.com/sectionl/image/upload/v1676972486/propertyPDFs/Hamamatsucho/HM-Hot_Water_itbtx7.pdf',
    '/property-info/slhm/hotwater2': 'https://res.cloudinary.com/sectionl/image/upload/v1678847995/propertyPDFs/Hamamatsucho/HM_Hot_Water_2_ngr1eb.pdf',
    '/property-info/slhm/ricecooker': 'https://res.cloudinary.com/sectionl/image/upload/v1676972486/propertyPDFs/Hamamatsucho/HM-Rice_Cooker_i2fpgf.pdf',
    '/property-info/slhm/house-appliance-guide/ja': 'https://res.cloudinary.com/sectionl/image/upload/v1677500225/propertyPDFs/Hamamatsucho/HM_Appliance_Guide_Japanese_oj6ius.pdf',
    '/property-info/sltk/aircon': 'https://res.cloudinary.com/sectionl/image/upload/v1685069378/propertyPDFs/Tsukiji/TK_Aircon1_ovod8a.pdf',
    '/property-info/sltk/aircon2': 'https://res.cloudinary.com/sectionl/image/upload/v1685069378/propertyPDFs/Tsukiji/TK_Aircon2_ywxiuu.pdf',
    '/property-info/sltk/tvremote': 'https://res.cloudinary.com/sectionl/image/upload/v1685069379/propertyPDFs/Tsukiji/TK_TVRemote_pqxma0.pdf',
    '/property-info/sltk/washingmachine': 'https://res.cloudinary.com/sectionl/image/upload/v1685069379/propertyPDFs/Tsukiji/TK_Washing_Machine_xzztr1.pdf',
    '/property-info/sltk/microwave': 'https://res.cloudinary.com/sectionl/image/upload/v1685069378/propertyPDFs/Tsukiji/TK_Microwave_ydffkj.pdf',
    '/property-info/sltk/hotwater': 'https://res.cloudinary.com/sectionl/image/upload/v1685069378/propertyPDFs/Tsukiji/TK_HotWater_iaw6qr.pdf',
    '/property-info/sltk/washlet': 'https://res.cloudinary.com/sectionl/image/upload/v1685069379/propertyPDFs/Tsukiji/TK_Washlet_t6gmss.pdf',
    '/property-info/sltk/ricecooker': 'https://res.cloudinary.com/sectionl/image/upload/v1685069378/propertyPDFs/Tsukiji/TK_RiceCooker_b1pexo.pdf',
    '/property-info/sltk/humidifier': 'https://res.cloudinary.com/sectionl/image/upload/v1685069378/propertyPDFs/Tsukiji/TK_Airpurifier_ih7m1q.pdf',
    '/property-info/sltk/house-appliance-guide/en': 'https://res.cloudinary.com/sectionl/image/upload/v1685454495/propertyPDFs/Tsukiji/TK_Digital_Appliance_Guide_English_scgiwt.pdf',
    '/property-info/sltk/house-appliance-guide/ja': 'https://res.cloudinary.com/sectionl/image/upload/v1685454487/propertyPDFs/Tsukiji/TK_Digital_Appliance_Guide_Japanese_ityzri.pdf',
    '/property-info/slys/house-appliance-guide/en': 'https://res.cloudinary.com/sectionl/image/upload/v1685454195/propertyPDFs/Yushima/YS_Digital_Appliance_Guide_English_ytlggd.pdf',
    '/property-info/slys/house-appliance-guide/ja': 'https://res.cloudinary.com/sectionl/image/upload/v1685454195/propertyPDFs/Yushima/YS_Digital_Appliance_Guide_Japanese_ohqkut.pdf',
    '/property-info/slys/aircon': 'https://res.cloudinary.com/sectionl/image/upload/v1685435246/propertyPDFs/Yushima/YS-AirCon_wwwgfa.pdf',
    '/property-info/slys/tvremote': 'https://res.cloudinary.com/sectionl/image/upload/v1685435247/propertyPDFs/Yushima/YS-TV_Remote_wxl3pv.pdf',
    '/property-info/slys/washingmachine': 'https://res.cloudinary.com/sectionl/image/upload/v1685435248/propertyPDFs/Yushima/YS-Washing_Machine_u2aaqk.pdf',
    '/property-info/slys/microwave': 'https://res.cloudinary.com/sectionl/image/upload/v1685435246/propertyPDFs/Yushima/YS-Microwave_dlh3xi.pdf',
    '/property-info/slys/hotwater': 'https://res.cloudinary.com/sectionl/image/upload/v1685435247/propertyPDFs/Yushima/YS-Hot_Water_sirvwh.pdf',
    '/property-info/slys/washlet': 'https://res.cloudinary.com/sectionl/image/upload/v1685435247/propertyPDFs/Yushima/YS-Washlet_xdsimf.pdf',
    '/property-info/slys/ricecooker': 'https://res.cloudinary.com/sectionl/image/upload/v1685435246/propertyPDFs/Yushima/YS-Rice_Cooker_l11irj.pdf',
    '/property-info/slkm/house-appliance-guide/en': 'https://res.cloudinary.com/sectionl/image/upload/v1685454104/propertyPDFs/Kuramae/KM_Digital_Appliance_Guide_English_koedtm.pdf',
    '/property-info/slkm/house-appliance-guide/ja': 'https://res.cloudinary.com/sectionl/image/upload/v1685454103/propertyPDFs/Kuramae/KM_Digital_Appliance_Guide_Japanese_hfwxlr.pdf',
    '/property-info/slkm/aircon': 'https://res.cloudinary.com/sectionl/image/upload/v1685435405/propertyPDFs/Kuramae/KM-AirCon_myitb8.pdf',
    '/property-info/slkm/tvremote': 'https://res.cloudinary.com/sectionl/image/upload/v1685435405/propertyPDFs/Kuramae/KM-TV_Remote_mgpta6.pdf',
    '/property-info/slkm/washingmachine': 'https://res.cloudinary.com/sectionl/image/upload/v1685435405/propertyPDFs/Kuramae/KM-Washing_Machine_sul7cp.pdf',
    '/property-info/slkm/microwave': 'https://res.cloudinary.com/sectionl/image/upload/v1685435404/propertyPDFs/Kuramae/KM-Microwave_axcsm8.pdf',
    '/property-info/slkm/hotwater': 'https://res.cloudinary.com/sectionl/image/upload/v1685435404/propertyPDFs/Kuramae/KM-Hot_Water_irrpl1.pdf',
    '/property-info/slkm/washlet': 'https://res.cloudinary.com/sectionl/image/upload/v1685435405/propertyPDFs/Kuramae/KM-Washlet_d5dhel.pdf',
    '/property-info/slkm/ricecooker': 'https://res.cloudinary.com/sectionl/image/upload/v1685435404/propertyPDFs/Kuramae/KM-Rice_Cooker_vesvug.pdf',
    '/property-info/slkm/lightremote': 'https://res.cloudinary.com/sectionl/image/upload/v1685435404/propertyPDFs/Kuramae/KM-Light_Remote_a07ux6.pdf',
    '/property-info/slkm/lightremote2': 'https://res.cloudinary.com/sectionl/image/upload/v1687231156/propertyPDFs/Kuramae/KM-Light_Remote_2_rellzz.pdf',
    '/property-info/slkm/lightremote3': 'https://res.cloudinary.com/sectionl/image/upload/v1687231157/propertyPDFs/Kuramae/KM-Light_Remote_3_orjic3.pdf',
    '/property-info/slsw/rental-list-en': 'https://res.cloudinary.com/sectionl/image/upload/v1725847504/propertyPDFs/Shimbashi%20West/SLSW_Rental_List_EN_xncif9.pdf',
    '/property-info/slsw/rental-list-ja': 'https://res.cloudinary.com/sectionl/image/upload/v1725847504/propertyPDFs/Shimbashi%20West/SLSW_Rental_List_JA_lidjir.pdf',
    '/property-info/slsw/rental-list-zh': 'https://res.cloudinary.com/sectionl/image/upload/v1725872965/propertyPDFs/Shimbashi%20West/SLSW_Rental_List_ZH_hanxgq.pdf',
    '/property-info/slsw/rental-list-zh-tw': 'https://res.cloudinary.com/sectionl/image/upload/v1725872965/propertyPDFs/Shimbashi%20West/SLSW_Rental_List_ZH-TW_hxslwf.pdf',
    '/property-info/slys/rental-list-en': 'https://res.cloudinary.com/sectionl/image/upload/v1686293457/propertyPDFs/SSA_Rental_List_EN_sfpnmi.pdf',
    '/property-info/slkm/rental-list-en': 'https://res.cloudinary.com/sectionl/image/upload/v1686293457/propertyPDFs/SSA_Rental_List_EN_sfpnmi.pdf',
    '/property-info/sltk/rental-list-en': 'https://res.cloudinary.com/sectionl/image/upload/v1686293457/propertyPDFs/SSA_Rental_List_EN_sfpnmi.pdf',
    '/property-info/slys/rental-list-ja': 'https://res.cloudinary.com/sectionl/image/upload/v1686293457/propertyPDFs/SSA_Rental_List_JA_xgsdqj.pdf',
    '/property-info/slkm/rental-list-ja': 'https://res.cloudinary.com/sectionl/image/upload/v1686293457/propertyPDFs/SSA_Rental_List_JA_xgsdqj.pdf',
    '/property-info/sltk/rental-list-ja': 'https://res.cloudinary.com/sectionl/image/upload/v1686293457/propertyPDFs/SSA_Rental_List_JA_xgsdqj.pdf',
    '/property-info/sluh/house-appliance-guide/en': 'https://res.cloudinary.com/sectionl/image/upload/v1698194937/propertyPDFs/Ueno-Hirokoji/UH_Digital_Appliance_Guide_EN_mibrro.pdf',
    '/property-info/sluh/house-appliance-guide/ja': 'https://res.cloudinary.com/sectionl/image/upload/v1698194926/propertyPDFs/Ueno-Hirokoji/UH_Digital_Appliance_Guide_JA_raotwv.pdf',
    '/property-info/sluh/house-appliance-guide/zh': 'https://res.cloudinary.com/sectionl/image/upload/v1698194926/propertyPDFs/Ueno-Hirokoji/UH_Digital_Appliance_Guide_ZH_htzxpa.pdf',
    '/property-info/sluh/house-appliance-guide/zh-tw': 'https://res.cloudinary.com/sectionl/image/upload/v1698194928/propertyPDFs/Ueno-Hirokoji/UH_Digital_Appliance_Guide_ZH-tw_fircoy.pdf',
    '/property-info/sluh/aircon': 'https://res.cloudinary.com/sectionl/image/upload/v1698194924/propertyPDFs/Ueno-Hirokoji/UH_AirCon_yvlxk5.pdf',
    '/property-info/sluh/tvremote': 'https://res.cloudinary.com/sectionl/image/upload/v1698194928/propertyPDFs/Ueno-Hirokoji/UH_TV_Remote_qpthvo.pdf',
    '/property-info/sluh/washingmachine': 'https://res.cloudinary.com/sectionl/image/upload/v1698194928/propertyPDFs/Ueno-Hirokoji/UH_Washing_Machine_k8jdbt.pdf',
    '/property-info/sluh/microwave': 'https://res.cloudinary.com/sectionl/image/upload/v1698194927/propertyPDFs/Ueno-Hirokoji/UH_Microwave_cmlxvm.pdf',
    '/property-info/sluh/hotwater': 'https://res.cloudinary.com/sectionl/image/upload/v1698194924/propertyPDFs/Ueno-Hirokoji/UH_Hot_Water_wgc3xh.pdf',
    '/property-info/sluh/washlet': 'https://res.cloudinary.com/sectionl/image/upload/v1698194928/propertyPDFs/Ueno-Hirokoji/UH_Washlet_kju4h1.pdf',
    '/property-info/sluh/ricecooker': 'https://res.cloudinary.com/sectionl/image/upload/v1698194926/propertyPDFs/Ueno-Hirokoji/UH_Rice_Cooker_dpc5mf.pdf',
    '/property-info/sluh/humidifier': 'https://res.cloudinary.com/sectionl/image/upload/v1698194925/propertyPDFs/Ueno-Hirokoji/UH_Humidifier_vmskiv.pdf',
    '/property-info/sluh/toaster': 'https://res.cloudinary.com/sectionl/image/upload/v1698194927/propertyPDFs/Ueno-Hirokoji/UH_Toaster_x7fbgp.pdf',
    '/property-info/sluh/stove': 'https://res.cloudinary.com/sectionl/image/upload/v1700203879/propertyPDFs/Stove_Type_1_gmjqzw.pdf',
    '/property-info/slhb/stove': 'https://res.cloudinary.com/sectionl/image/upload/v1700203879/propertyPDFs/Stove_Type_1_gmjqzw.pdf',
    '/property-info/slkm/stove': 'https://res.cloudinary.com/sectionl/image/upload/v1700203879/propertyPDFs/Stove_Type_1_gmjqzw.pdf',
    '/property-info/slys/stove': 'https://res.cloudinary.com/sectionl/image/upload/v1700203879/propertyPDFs/Stove_Type_1_gmjqzw.pdf',
    '/property-info/slhm/stove': 'https://res.cloudinary.com/sectionl/image/upload/v1700203878/propertyPDFs/Stove_Type_2_fzotee.pdf',
    '/property-info/sltk/stove': 'https://res.cloudinary.com/sectionl/image/upload/v1700203878/propertyPDFs/Stove_Type_2_fzotee.pdf',
    '/property-info/slae/house-appliance-guide/zh': 'https://res.cloudinary.com/sectionl/image/upload/v1707089884/propertyPDFs/Asakusa%20East/AE_Appliance_Guide_Simplified_Chinese_vskf1i.pdf',
    '/property-info/slae/house-appliance-guide/zh-tw': 'https://res.cloudinary.com/sectionl/image/upload/v1707089884/propertyPDFs/Asakusa%20East/AE_Appliance_Guide_Traditional_Chinese_wujpl5.pdf',
    '/property-info/slge/house-appliance-guide/zh': 'https://res.cloudinary.com/sectionl/image/upload/v1707090172/propertyPDFs/Ginza%20East/GE_Appliance_Guide_Simplified_Chinese_oe1erb.pdf',
    '/property-info/slge/house-appliance-guide/zh-tw': 'https://res.cloudinary.com/sectionl/image/upload/v1707090173/propertyPDFs/Ginza%20East/GE_Appliance_Guide_Traditional_Chinese_lluxap.pdf',
    '/property-info/slhb/house-appliance-guide/zh': 'https://res.cloudinary.com/sectionl/image/upload/v1707090299/propertyPDFs/Hatchobori/HB_Appliance_Guide_Simplified_Chinese_gzmbgd.pdf',
    '/property-info/slhb/house-appliance-guide/zh-tw': 'https://res.cloudinary.com/sectionl/image/upload/v1707090303/propertyPDFs/Hatchobori/HB_Appliance_Guide_Traditional_Chinese_rsj4kg.pdf',
    '/property-info/sltk/house-appliance-guide/zh': 'https://res.cloudinary.com/sectionl/image/upload/v1707090383/propertyPDFs/Tsukiji/TK_Digital_Appliance_Guide_Simplified_Chinese_giqdqs.pdf',
    '/property-info/sltk/house-appliance-guide/zh-tw': 'https://res.cloudinary.com/sectionl/image/upload/v1707090377/propertyPDFs/Tsukiji/TK_Digital_Appliance_Guide_Traditional_Chinese_pgnzbr.pdf',
    '/property-info/slkm/house-appliance-guide/zh': 'https://res.cloudinary.com/sectionl/image/upload/v1707090454/propertyPDFs/Kuramae/KM_Digital_Appliance_Guide_Simplified_Chinese_c8edba.pdf',
    '/property-info/slkm/house-appliance-guide/zh-tw': 'https://res.cloudinary.com/sectionl/image/upload/v1707090444/propertyPDFs/Kuramae/KM_Digital_Appliance_Guide_Traditional_Chinese_uuxqu5.pdf',
    '/property-info/slys/house-appliance-guide/zh': 'https://res.cloudinary.com/sectionl/image/upload/v1707090515/propertyPDFs/Yushima/YS_Digital_Appliance_Guide_Simplified_Chinese_gxgkut.pdf',
    '/property-info/slys/house-appliance-guide/zh-tw': 'https://res.cloudinary.com/sectionl/image/upload/v1707090509/propertyPDFs/Yushima/YS_Digital_Appliance_Guide_Traditional_Chinese_uaybtd.pdf',
  }).map(([path, externalPath]) => ({
    path,
    redirect: () => {
      window.location.href = externalPath
      return '/redirecting' // not important since redirecting
    },
  }))),
]

export default [
  {
    path: '/',
    name: 'Root',
    component: () => import('@/layouts/Default.vue'),
    props: route => ({
      header: route.meta.header,
      bottomNav: route.meta.bottomNav,
      classes: route.meta.classes,
      whiteBackground: route.meta.whiteBackground,
    }),
    children: [
      {
        path: '',
        name: 'Start',
        component: () => import('@/pages/Start.vue'),
        props: route => ({ emailToPrefill: route.query.email }),
        meta: {
          title: 'Start',
          authRequired: false,
          beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
              // Redirect to the home tab
              next({ name: 'Home' })
            } else {
              // Continue to the start page
              next()
            }
          },
        },
      },
      {
        path: '',
        name: 'StartDisabled',
        component: () => import('@/pages/StartDisabled.vue'),
        props: true,
        meta: {
          title: 'Start',
          beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
              // Redirect to the home tab
              next({ name: 'Home' })
            } else {
              // Continue to the start page
              next()
            }
          },
        },
      },
      // The navRoutes should come after the 'Start' route
      ...navRoutes(),
      ...oldNavRoutes(), // remove after city gems release
      ...redirectRoutes(),
      {
        path: '/setup-profile',
        name: 'SetupProfile',
        component: () => import('@/pages/SetupProfile.vue'),
        meta: {
          title: 'Setup your profile',
        },
      },
      {
        path: '/setup-complete',
        name: 'SetupComplete',
        component: () => import('@/pages/SetupComplete.vue'),
        meta: {
          title: 'Profile Setup Completed',
        },
      },
      {
        path: '/logout',
        name: 'Logout',
        component: { template: '<div></div>' },
        props: true,
        meta: {
          title: 'Logout',
          async beforeResolve(to, from, next) {
            await store.dispatch('auth/logOut')
            next({ name: 'Start' })
          },
        },
      },
      {
        path: '/magic_login',
        name: 'MagicLogin',
        component: () => import('@/pages/MagicLogin.vue'),
        props: route => route.query,
        meta: {
          title: 'MagicLogin',
          authRequired: false,
        },
      },
      {
        path: '/email-sent',
        name: 'EmailSent',
        component: () => import('@/pages/EmailSent.vue'),
        props: true,
        meta: {
          title: 'Email sent',
          authRequired: false,
        },
      },
      {
        path: '/email-not-found',
        name: 'EmailNotFound',
        component: () => import('@/pages/EmailNotFound.vue'),
        meta: {
          title: 'Email not found',
          authRequired: false,
        },
      },
      {
        path: '/login-instructions',
        name: 'MagicLinkSent',
        component: () => import('@/pages/MagicLinkSent.vue'),
        props: route => ({
          email: route.params.email,
          redirectTo: route.params.redirectTo,
        }),
        meta: {
          title: 'InterSection Login',
          authRequired: false,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: { name: 'Home' },
  },
]

