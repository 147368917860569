export const state = {
  neighborhood: undefined, // need to set default neighborhood for non instay guest
  cityGems: {
    category: 'all',
  },
  tags: new Set([])
}

export const getters = {
  neighborhood(state) {
    return state.neighborhood
  },
  cityGemsFilters(state) {
    return state.cityGems
  },
  cityGemsCategory(state) {
    return state.cityGems.category
  },
  tags(state) {
    return state.tags
  }
}

export const mutations = {
  SET_NEIGHBORHOOD_FILTER(state, neighborhood) {
    state.neighborhood = neighborhood
  },
  SET_CITYGEMS_FILTER(state, filters) {
    state.cityGems = filters
  },
  SET_TAGS_FILTER(state, tags) {
    state.tags = tags
  },
  TOGGLE_TAG(state, tag) {
    state.tags.has(tag)
      ? state.tags.delete(tag)
      : state.tags.add(tag)

  }
}

export const actions = {
  async setNeighborhood({ commit, dispatch }, neighborhood = undefined) {
    commit('SET_NEIGHBORHOOD_FILTER', neighborhood)
    dispatch('resetTagFilter')
  },

  async setCityGemsFilter({ commit }, cityGemsFilter = {
    category: 'all',
  }) {
    commit('SET_CITYGEMS_FILTER', { cityGemsFilter })
  },

  async setCityGemsCategory({ commit }, category) {
    commit('SET_CITYGEMS_FILTER', { ...state.cityGems, category })
  },

  setRandomNeighborhood({ commit, dispatch, rootGetters }) {
    const currentNeighborhoods = rootGetters['neighborhoods/current']
    dispatch('resetTagFilter')

    if (!currentNeighborhoods) {
      const allNeighborhoods = rootGetters['neighborhoods/all']

      const randomlyChosenNeighborhood = allNeighborhoods[Math.floor(Math.random() * allNeighborhoods.length)]
      commit('SET_NEIGHBORHOOD_FILTER', randomlyChosenNeighborhood)
      return
    }

    const randomlyChosenNeighborhood = currentNeighborhoods[Math.floor(Math.random() * currentNeighborhoods.length)]
    commit('SET_NEIGHBORHOOD_FILTER', randomlyChosenNeighborhood)
  },

  setTagFilter({ commit, dispatch }, tags) {
    dispatch('setCityGemsCategory', 'all')
    commit('SET_TAGS_FILTER', tags)

  },
  resetTagFilter({ commit, dispatch }) {
    dispatch('setCityGemsCategory', 'all')
    commit('SET_TAGS_FILTER', new Set())
  },
  async setDefaultNeighborhood({ commit, dispatch, rootGetters }) {
    dispatch('resetTagFilter')

    if (rootGetters['auth/currentProfile']?.property) {
      commit('SET_NEIGHBORHOOD_FILTER', rootGetters['neighborhoods/randCurrent'])
    } else {
      commit('SET_NEIGHBORHOOD_FILTER', rootGetters['neighborhoods/randAll'])
    }
  },
  toggleTag({ dispatch, commit }, tag: string) {
    console.log('hello')
    dispatch('setCityGemsCategory', 'all')
    commit('TOGGLE_TAG', tag)
  }
}
