import qs from 'qs'
import { config } from '@/constants'
import store from '@/store'
import { computed, ref, watch } from 'vue'

export function useCms() {
  const baseUrl = config.cms.apiUrl
  const headers = new Headers()

  const cityGems = ref<{ [key: string]: any }[]>([]) // TODO: type this properly
  const tags = ref<string[]>([])
  const neighborhoodFilter = computed(() => store.getters['exploreFilters/neighborhood'])
  const category = computed(() => store.getters['exploreFilters/cityGemsCategory'])
  const tagsFilter = computed(() => store.getters['exploreFilters/tags'])

  const changeCategory = async selectedCategory => {
    await store.dispatch('exploreFilters/setCityGemsCategory', selectedCategory)
  }

  if (store?.getters['auth/currentUser']?.jwtToken) {
    headers.append('Authorization', store?.getters['auth/currentUser']?.jwtToken)
  }

  const fetchNearbyCityGems = async ({ neighborhoodDocumentId }) => {
    const url = new URL('/api/city-gems', baseUrl)
    const query = qs.stringify(
      {
        filters: {
          neighborhoods: {
            documentId: {
              $eq: neighborhoodDocumentId
            }
          },
          coverImage: {
            $notNull: true
          },
          tags: {
            $notNull: true
          }
        },
        populate: {
          coverImage: {
            fields: ['url'],
          },
          tags: {
            fields: ['name']
          }
        },
        pagination: {
          limit: 5
        }
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    url.search = query;

    const response = await fetch(url.toString(), { headers })
    const data = await response.json()

    return data
  }

  const fetchCityGems = async ({ neighborhoodDocumentId }) => {
    const url = new URL('/api/city-gems', baseUrl)
    const query = qs.stringify(
      {
        filters: {
          neighborhoods: {
            documentId: {
              $eq: neighborhoodDocumentId
            }
          },
          coverImage: {
            $notNull: true
          },
          tags: {
            $notNull: true
          }
        },
        populate: {
          coverImage: {
            fields: ['url'],
          },
          tags: {
            fields: ['name']
          }
        },
        pagination: {
          limit: 50
        }
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    url.search = query;

    const response = await fetch(url.toString(), { headers })
    const data = await response.json()

    return data
  }

  const fetchNeighborhoodTags = async ({ neighborhoodDocumentId }) => {
    const url = new URL('/api/tags', baseUrl)
    const query = qs.stringify(
      {
        filters: {
          city_gems: {
            neighborhoods: {
              documentId: {
                $eq: neighborhoodDocumentId
              }
            }
          },
        }
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    url.search = query;
    const response = await fetch(url.toString(), { headers })
    const { data } = await response.json()
    return data.map((tag) => tag.name)
  }

  const fetchNeighborhoods = async () => {
    const url = new URL('/api/neighborhoods?populate=properties', baseUrl)
    const response = await fetch(url.toString(), { headers }) //handle error somehow
    const { data } = await response.json()
    return data
  }
  const filteredCityGems = computed(() => {
    let gems = cityGems.value;

    // Step 1: Filter by tags.
    if (tagsFilter.value.size > 0) {
      gems = gems.filter((cityGem) => {
        const cityGemTagName = new Set(cityGem.tags.map((tag) => tag.name));
        console.log(tagsFilter.value, cityGemTagName)
        return new Set([...tagsFilter.value]).intersection(cityGemTagName).size > 0;
      });
    }

    // Step 2: Filter by category if category is not 'all'
    if (category.value !== 'all') {
      gems = gems.filter((cityGem) => cityGem.category === category.value);
    }

    return gems;
  });

  watch(
    neighborhoodFilter,
    async () => {
      const { data: cityGemsData } = await fetchCityGems({
        neighborhoodDocumentId: neighborhoodFilter.value?.documentId,
      })
      cityGems.value = cityGemsData

      const tagsData = await fetchNeighborhoodTags({
        neighborhoodDocumentId: neighborhoodFilter.value?.documentId,
      })
      tags.value = tagsData
    },
    { immediate: true }
  )

  const isFilterApplied = computed(() => tagsFilter.value.size > 0)


  return {
    fetchCityGems,
    fetchNearbyCityGems,
    fetchNeighborhoods,
    fetchNeighborhoodTags,
    category,
    neighborhoodFilter,
    changeCategory,
    filteredCityGems,
    isFilterApplied,
    tags,
    cityGems
  }
}
